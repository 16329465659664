import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["sessionTimeoutModal", "layerOneModal"];
  sessionTimeoutModalTarget?: HTMLElement;
  hasSessionTimeoutModalTarget?: boolean;
  layerOneModalTargets?: Array<HTMLElement>;

  initialize() {
    this.pollForSessionTimeout();
  }

  showSessionTimeoutModal() {
    this.toggleSessionTimeoutModal("invisible", "visible");
    this.hideLayerOneModals();
  }

  hideSessionTimeoutModal() {
    this.toggleSessionTimeoutModal("visible", "invisible");
    this.showLayerOneModals();
  }

  showLayerOneModals() {
    this.layerOneModalTargets?.forEach((modal) =>
      modal.classList.remove("invisible")
    );
  }

  hideLayerOneModals() {
    this.layerOneModalTargets?.forEach((modal) =>
      modal.classList.add("invisible")
    );
  }

  signOut = () => {
    window.location.href = "/logout";
  };

  private toggleSessionTimeoutModal(from: string, to: string) {
    if (!this.hasSessionTimeoutModalTarget) return;

    this.sessionTimeoutModalTarget?.classList.remove(from);
    this.sessionTimeoutModalTarget?.classList.add(to);
  }

  extendSession = () => {
    let request = new XMLHttpRequest();
    request.open("GET", "/extend_session", true);
    request.send();
    this.hideSessionTimeoutModal();
  };

  pollForSessionTimeout = () => {
    let request = new XMLHttpRequest();
    request.onload = (event) => {
      let ttl = request.response;
      let status = request.status;

      if (status === 200) {
        if (ttl <= 0) {
          window.location.href = "/logout";
        } else if (ttl <= 60) {
          this.showSessionTimeoutModal();
        }
      }

      setTimeout(this.pollForSessionTimeout, this.nextPoll(ttl));
    };
    request.open("GET", "/check_session_timeout", true);
    request.responseType = "json";
    request.send();
  };

  nextPoll = (ttl: number /*[seconds]*/): number /*[milliseconds]*/ => {
    if (ttl <= 120) {
      return 60 * 1000;
    } else {
      return (ttl - 120) * 1000;
    }
  };
}
