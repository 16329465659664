import { Controller } from "stimulus";

export default class extends Controller {
  billPrintId?: string;
  generating: boolean = false;
  idValue?: string;
  printUrlValue?: string;
  refreshInterval: number = 2000;
  refreshTimer?: ReturnType<typeof setTimeout>;
  spinnerTarget?: any;

  billErrorElement?: Element | null;
  networkErrorFlashElement?: Element | null;

  static targets = ["spinner"];

  static values = {
    printUrl: String,
    id: String,
    authToken: String,
  };

  createBillPrint(e: any) {
    e.preventDefault();

    if (this.generating) return;
    this.generating = true;
    this.displayLoadingSpinner();

    fetch(`${this.printUrlValue}?bill_id=${this.idValue}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "complete") {
          this.hideLoadingSpinner();
          this.generating = false;
          window.location.href = data.download_url;
        } else if (data.flash) {
          this.hideLoadingSpinner();
          this.generating = false;
          this.addFlashToDOM(data.flash);
        } else {
          this.billPrintId = data.id;
          this.startPolling();
        }
      })
      .catch(() => {
        this.generating = false;
        this.hideLoadingSpinner();
        this.displayNetworkErrorFlash();
      });
  }

  fetchBill() {
    fetch(`${this.printUrlValue}/${this.billPrintId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "complete") {
          window.location.href = data.download_url;
          this.stopPolling();
        } else if (data.flash) {
          this.addFlashToDOM(data.flash);
          this.stopPolling();
        }
      })
      .catch((e) => {
        this.displayNetworkErrorFlash();
      });
  }

  startPolling() {
    this.refreshTimer = setInterval(() => {
      this.fetchBill();
    }, this.refreshInterval);
  }

  stopPolling() {
    this.hideLoadingSpinner();
    this.generating = false;
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  addFlashToDOM(flashHTML: string) {
    this.billErrorElement = document.querySelector("#errors");
    this.billErrorElement!.insertAdjacentHTML("afterbegin", flashHTML);
  }

  displayNetworkErrorFlash() {
    this.networkErrorFlashElement = document.querySelectorAll(
      "[data-error-hidden=true]"
    )[0];
    this.networkErrorFlashElement!.classList.remove("hidden");
  }

  displayLoadingSpinner() {
    this.spinnerTarget?.classList.remove("invisible");
    this.spinnerTarget?.removeAttribute("hidden");
    this.spinnerTarget?.setAttribute("role", "alert");
  }

  hideLoadingSpinner() {
    this.spinnerTarget?.classList.add("invisible");
    this.spinnerTarget?.setAttribute("hidden", "true");
    this.spinnerTarget?.removeAttribute("role");
  }

  disconnect() {
    this.stopPolling();
  }
}
