import { Controller } from "stimulus";

const FILE_SIZE_LIMIT = 5.0;
const FILE_SIZE_LIMIT_BYTES = 1000000000 * FILE_SIZE_LIMIT;
const MAX_NUMBER_OF_FILES = 20;

type DocumentCreated = {
  id: number;
  filename: string;
  latest_document_version: {
    uuid: string;
    put_url: string;
    put_headers: [
      { name: "x-amz-server-side-encryption"; value: "AES256" },
      { name: "Content-Type"; value: string }
    ];
  };
};

type BulkUploadError = {
  [filename: string]: {
    message: string;
    id?: number;
  };
};

type DocumentCreatedResponse = {
  documents: DocumentCreated[];
  error?: BulkUploadError;
};

type S3BulkUploadResponse = {
  documentsUploaded: {
    id: number;
    uuid: string;
  }[];
  error?: BulkUploadError;
};

enum HTTP_METHOD {
  Post = "POST",
  Put = "PUT",
}

// Reduces the request size to avoid HTTP 413 error that occurs in beta
export const sanitizeFormData = (formData: FormData): FormData => {
  const originalDocs = formData.getAll("documents[]") as File[];
  formData.delete("documents[]");

  for (let i = 0; i < originalDocs.length; i++) {
    const document = originalDocs[i];

    if (!(document instanceof File)) continue;
    const file = new File([""], document.name, { type: document.type });
    Object.defineProperty(file, "size", { value: document.size });
    formData.append("documents[]", file);
  }

  return formData;
};
export default class extends Controller {
  static targets = [
    "attachmentFields",
    "form",
    "fileField",
    "textArea",
    "formSubmit",
    "documentList",
    "attachmentHeader",
    "tooManyDocumentsWarning",
    "messageSentOverlay",
    "progressBarOverlay",
    "progressBar",
    "progressBarMessage",
    "removeOrReplaceErrorMessage",
    "removeOrReplaceErrorMessageLabel",
    "noDocumentsAttachedLabel",
    "flashContainer",
    "failedUploadFlash",
  ];

  static values = {
    submitWithButtonText: String,
    submitWithoutButtonText: String,
    fileSizeWarningText: String,
    clickToReplaceText: String,
    attachmentText: String,
    attachmentsText: String,
    sendingDocumentsSingularText: String,
    sendingDocumentsPluralText: String,
    replaceOrRemoveSingularText: String,
    replaceOrRemovePluralText: String,
    attachText: String,
    cancelText: String,
    cancelScreenReaderText: String,
    fromUploadButton: Boolean,
    classAttachButtonDefault: String,
    classAttachButtonCancel: String,
    sendingDocumentText: String,
    sendingDocumentsText: String,
    unableToSendPleaseReplaceText: String,
    uploadErrors: Object,
  };

  globalFileList: DataTransfer = new DataTransfer();
  uploadedDocumentCount: number = 0;
  retryUpload: boolean = false;
  attachmentFieldsTarget?: Element;
  attachmentHeaderTarget?: Element;
  formTarget?: Element;
  textAreaTarget?: HTMLInputElement;
  fileFieldTarget?: HTMLInputElement;
  formSubmitTarget?: HTMLInputElement;
  documentListTarget?: Element;
  tooManyDocumentsWarningTarget?: Element;
  failedUploadFlashTarget?: Element;
  messageSentOverlayTarget?: HTMLElement;
  progressBarOverlayTarget?: HTMLElement;
  progressBarTarget?: HTMLElement;
  progressBarMessageTarget?: HTMLElement;
  removeOrReplaceErrorMessageTarget?: HTMLElement;
  removeOrReplaceErrorMessageLabelTarget?: Element;
  noDocumentsAttachedLabelTarget?: Element;
  flashContainerTarget?: Element;
  submitWithButtonTextValue?: string;
  submitWithoutButtonTextValue?: string;
  fileSizeWarningTextValue?: string;
  clickToReplaceTextValue?: string;
  attachmentTextValue?: string;
  attachmentsTextValue?: string;
  sendingDocumentsSingularTextValue?: string;
  sendingDocumentsPluralTextValue?: string;
  replaceOrRemoveSingularTextValue?: string;
  replaceOrRemovePluralTextValue?: string;
  attachTextValue?: string;
  cancelTextValue?: string;
  cancelScreenReaderTextValue?: string;
  fromUploadButtonValue?: boolean;
  classAttachButtonDefaultValue?: string;
  classAttachButtonCancelValue?: string;
  sendingDocumentTextValue?: string;
  sendingDocumentsTextValue?: string;
  unableToSendPleaseReplaceTextValue?: string;
  uploadErrorsValue?: BulkUploadError;

  initialize() {
    document.getElementById("successfullySentPopupVoiceover")?.focus();
  }

  clickFileField(event: Event) {
    this.fileFieldTarget?.click();
  }

  async onSend(event: Event) {
    if (this.fileFieldTarget) {
      //Update the file payload and send the mapped list
      this.fileFieldTarget.files = this.globalFileList.files;
    }

    if (
      this.documentListTarget?.children &&
      this.documentListTarget?.children?.length > MAX_NUMBER_OF_FILES
    ) {
      this.tooManyDocumentsWarningTarget?.classList.remove("hidden");
      event.preventDefault();
      return false;
    }

    if (this.formHasFilesWithErrors()) {
      this.removeOrReplaceErrorMessageTarget?.classList.remove("hidden");
      this.removeOrReplaceErrorMessageTarget?.focus();
      event.preventDefault();
      return false;
    }

    if (!this.textAreaTarget?.value && !this.fileFieldTarget?.value) {
      event.preventDefault();
      return false;
    } else {
      if (!this.fileFieldTarget?.value) {
        this.messageSentOverlayTarget?.classList.remove("hidden");
      }
    }

    if (this.noDocumentsAttachedLabelTarget) {
      this.noDocumentsAttachedLabelTarget.classList.add("hidden");
    }

    this.clearFlashAlerts();
    this.displayProgressBar(0, this.documentListTarget?.children.length ?? 1);

    if (this.fileFieldTarget?.files?.length) {
      event.preventDefault();

      // Step 1 - Create document records
      const {
        documents,
        error: submitDocumentError,
      } = await this.submitDocumentCreated();

      // Step 2 - Upload files to S3 one by one
      const {
        documentsUploaded,
        error: s3UploadError,
      } = await this.uploadDocumentsToS3(documents);
      const error: BulkUploadError = {
        ...submitDocumentError,
        ...s3UploadError,
      };

      // Step 3 - Mark documents as fully uploaded and sending them in client portal messsages
      await this.sendDocumentMessages({
        documentsUploaded,
        error,
      });
    }
  }

  retryFailedUploads(event: Event) {
    if (!this.uploadErrorsValue) {
      return;
    }

    const uuid = Date.now();

    Object.entries(this.uploadErrorsValue).forEach((entry, index) => {
      const fileName = entry[0];
      const errorMessage = entry[1].message;

      // Need a fake file to represent the failed upload in order for the replace/remove
      // functionality to properly replace the file in-place or remove it from the list of the files to send.
      // This isn't a problem since every file with an error MUST be removed or replaced before sending
      const errorFilePlaceholder = new File([], fileName, {
        lastModified: uuid + index, // lastModified only needs to be a unique identifier
      });
      this.globalFileList.items.add(errorFilePlaceholder);

      if (this.documentListTarget) {
        const htmlListItem = this.createErrorListItem(
          errorFilePlaceholder,
          errorMessage,
          index
        );
        this.documentListTarget.innerHTML += htmlListItem;
      }

      this.updateAttachmentHeaderCount();
    });

    this.failedUploadFlashTarget?.classList.add("hidden");
    this.attachmentFieldsTarget?.classList.remove("hidden");
    this.retryUpload = true;
  }

  getMessageInputForm() {
    const form = this.formTarget?.getElementsByTagName("form")[0];
    if (!form) {
      throw new Error("Malformed form");
    }
    return form;
  }

  getFolderID() {
    const folderSelector = document.getElementById(
      "folder-selector"
    ) as HTMLSelectElement;
    return folderSelector?.value;
  }

  async submitForm(form: HTMLFormElement, method: HTTP_METHOD) {
    try {
      if (!form.action) {
        throw new Error("Missing url from form");
      }

      const formData = sanitizeFormData(new FormData(form));
      if (this.retryUpload) {
        formData.append("retryUpload", "true");
      }

      const response = await fetch(form.action, {
        method,
        body: formData,
      });

      return await response.json();
    } catch (err) {
      throw err;
    }
  }

  async submitDocumentCreated() {
    let result: DocumentCreatedResponse = {
      documents: [],
    };

    try {
      const form = this.getMessageInputForm();
      result = (await this.submitForm(
        form,
        HTTP_METHOD.Post
      )) as DocumentCreatedResponse;
    } catch (err) {
      result.error = {};

      if (this.fileFieldTarget?.files) {
        for (let i = 0; i < this.fileFieldTarget.files.length; i++) {
          const file = this.fileFieldTarget.files.item(i);

          if (!!file) {
            result.error[file.name] = {
              message: this.unableToSendPleaseReplaceTextValue ?? "",
            };
          }
        }
      }
    }

    return result;
  }

  findFileByName(fileName: string) {
    if (!this.fileFieldTarget?.files) {
      return;
    }

    for (let i = 0; i < this.fileFieldTarget.files.length; i++) {
      const file = this.fileFieldTarget.files[i];
      if (file.name === fileName) {
        return file;
      }
    }
  }

  async uploadDocumentToS3(document: DocumentCreated) {
    const {
      id,
      filename,
      latest_document_version: { uuid, put_url, put_headers },
    } = document;

    const file = this.findFileByName(filename);

    try {
      const [sendFile, request] = this.createS3FileUploadRequest(
        file,
        put_headers,
        put_url
      );

      await sendFile();

      if (request.status === 200) {
        return { id, uuid };
      }
    } catch (err) {}
  }

  async uploadDocumentsToS3(documents: DocumentCreated[]) {
    const result: S3BulkUploadResponse = {
      documentsUploaded: [],
    };

    for (let i = 0; i < documents.length; i++) {
      const res = await this.uploadDocumentToS3(documents[i]);

      if (!!res) {
        const { id, uuid } = res;
        result.documentsUploaded.push({ id, uuid });
        this.uploadedDocumentCount = result.documentsUploaded.length;
      } else {
        const error: BulkUploadError = {
          [documents[i].filename]: {
            message: this.unableToSendPleaseReplaceTextValue ?? "",
            id: documents[i].id,
          },
        };
        result.error = !result.error ? error : { ...result.error, ...error };
      }
    }

    return result;
  }

  async sendDocumentMessages(s3BulkUploadResult: S3BulkUploadResponse) {
    try {
      const { action } = this.getMessageInputForm();
      const folder_id = this.getFolderID();
      const { documentsUploaded: documents, error } = s3BulkUploadResult;
      const message = documents.length == 1 ? this.textAreaTarget?.value : null;
      const { status, headers } = await fetch(`${action}/bulk_upload`, {
        method: HTTP_METHOD.Post,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          documents,
          error,
          message,
          folder_id,
        }),
      });

      const location = headers.get("Location") as string & Location;
      if (status === 201 && !!location) {
        window.location = location;
      }
    } catch (err) {}
  }

  onChangeAttachment() {
    if (this.noDocumentsAttachedLabelTarget) {
      this.noDocumentsAttachedLabelTarget.classList.add("hidden");
    }

    this.attachmentFieldsTarget?.classList.remove("hidden");

    if (this.fileFieldTarget?.files && this.documentListTarget) {
      const { files } = this.fileFieldTarget;
      this.documentListTarget.classList.remove("hidden");

      for (let i = 0; i < files.length; i++) {
        const currentFile = files[i];
        const sizeLimitExceeded = currentFile.size > FILE_SIZE_LIMIT_BYTES;

        const htmlListItem = sizeLimitExceeded
          ? this.createFileSizeErrorListItem(currentFile, i)
          : this.createListItem(currentFile);

        if (sizeLimitExceeded) {
          this.documentListTarget.innerHTML =
            htmlListItem + this.documentListTarget.innerHTML;
          document
            .getElementById("scrollForDocList")!
            .scroll({ top: 0, left: 0, behavior: "smooth" });
        } else {
          this.documentListTarget.innerHTML += htmlListItem;
        }
        this.addAttachment(this.fileFieldTarget?.files[i]);
      }
    }

    this.toggleTooManyDocumentsPopup();

    this.truncateExccessFiles();

    this.updateAttachmentHeaderCount();
  }

  createListItem(file: File) {
    const { name, type, lastModified } = file;
    const time = Date.now();

    return `
    <li id="document${time}" class="mt-1.5" data-filename="${file.name}">
      <div class="rounded-lg flex flex-row items-start px-2 py-2" style="border-width: 1px; border-color: #576C82; border-style:solid;" >
          <span class="relative p-1 rounded ${this.getIconColor(
            file.type
          )} mr-3">${this.getIconName(type)}</span>
            <p tabindex="-1" class="break-all flex-grow appearance-none rounded-md bg-nothing text-grey-700 text-body self-center mr-3">${name}</p>
          <button aria-label="Remove file" id="${time}" data-filename="${name}" data-lastmodified="${lastModified}"
    data-action="click->messages-input#onClear" class="h-8 w-8 rounded-full self-start bg-grey-200 items-center p-1">
            ${CLOSE_ICON}
          </button>
      </div>
    </li>
    `;
  }

  createErrorListItem(file: File, errorMessage: string, index: number) {
    const time = Date.now();

    return `
    <li id="document${time}" class="mt-1.5" data-filename="${file.name}">
      <div class="rounded-lg flex flex-row items-start px-2 py-2 contains-error" style="border-width: 2px; border-color: #E41B28; border-style:solid;" >
          <span class="relative p-1 rounded bg-white mr-3">${ALERT_CIRCLE}</span>
          <div id="${index}" class="flex flex-col flex-grow mr-3">
            <div>
        <p tabindex="-1" class="break-all flex-grow appearance-none rounded-md bg-nothing text-grey-700 text-body self-center">${file.name}</p>
            </div>
      <p class="break-all flex-grow appearance-none rounded-md bg-nothing text-red-500 mt-0.5 text-body">
              ${errorMessage}
            </p>
            <input id="${time}" type="file" class="hidden" data-action="change->messages-input#onReplace">
            <label class="bg-nothing text-grey-500 text-body focus:outline-none" for="${time}">${this.clickToReplaceTextValue}</label>
          </div>
          <button aria-label="Remove file" id="${time}" data-filename="${file.name}" data-lastmodified="${file.lastModified}"
            data-action="click->messages-input#onClear" class="h-8 w-8 rounded-full self-start bg-grey-200 items-center p-1">
          ${CLOSE_ICON}
          </button>
      </div>
    </li>
    `;
  }

  createFileSizeErrorListItem(file: File, index: number): string {
    const fileSizeErrorMessage =
      this.fileSizeWarningTextValue?.replace(
        "${FILE_SIZE_LIMIT}",
        FILE_SIZE_LIMIT.toString()
      ) ?? "";

    return this.createErrorListItem(file, fileSizeErrorMessage, index);
  }

  onReplace(event: any) {
    event.preventDefault();
    const newFile = event.currentTarget.files[0];
    const fileToBeReplacedElement = document.getElementById(
      `document${event.currentTarget.id}`
    )!;
    const { filename } = fileToBeReplacedElement.dataset;
    const newFileHtmlItem =
      newFile.size > FILE_SIZE_LIMIT_BYTES
        ? this.createFileSizeErrorListItem(
            newFile,
            parseInt(event.currentTarget.parentElement.id)
          )
        : this.createListItem(newFile);

    const div = document.createElement("div");
    div.innerHTML = newFileHtmlItem.trim();

    const newFileId = div.firstElementChild!.id;

    this.documentListTarget?.insertBefore(
      div.firstElementChild!,
      fileToBeReplacedElement
    );
    this.documentListTarget?.removeChild(fileToBeReplacedElement);

    document.getElementById(newFileId)?.focus();
    const newFileTextElement = document.querySelectorAll(
      `#${newFileId} p`
    )[0] as HTMLElement;
    newFileTextElement.focus();
    this.replaceAttachment(newFile, filename!);
  }

  onClear(event: any) {
    event.preventDefault();

    if (this.fileFieldTarget?.files) {
      const { filename, lastmodified } = event.currentTarget.dataset;
      this.removeAttachment(filename, lastmodified);
    }

    const nextElement = document.getElementById(
      `document${event.currentTarget.id}`
    )?.nextElementSibling as HTMLElement;
    const firstTextElement = document.querySelectorAll(
      `#${nextElement?.id} p`
    )[0] as HTMLElement;

    if (this.documentListTarget?.children.length === 1) {
      const attachDocumentsButton = document.getElementById(
        "attach_documents_button"
      ) as HTMLElement;
      attachDocumentsButton.focus();
    } else {
      firstTextElement?.focus();
    }

    this.documentListTarget?.removeChild(
      document.getElementById(`document${event.currentTarget.id}`)!
    );

    this.updateAttachmentHeaderCount();

    if (!this.formHasFilesWithErrors()) {
      this.removeOrReplaceErrorMessageTarget?.classList.add("hidden");
    }

    this.toggleTooManyDocumentsPopup();
  }

  formHasFilesWithErrors() {
    const filesWithErrorsCount = document.querySelectorAll(".contains-error")
      .length;
    const hasErrors = filesWithErrorsCount > 0;

    if (
      hasErrors &&
      this.removeOrReplaceErrorMessageLabelTarget &&
      this.replaceOrRemoveSingularTextValue &&
      this.replaceOrRemovePluralTextValue
    ) {
      const replaceOrRemoveString =
        filesWithErrorsCount === 1
          ? this.replaceOrRemoveSingularTextValue
          : this.replaceOrRemovePluralTextValue;
      this.removeOrReplaceErrorMessageLabelTarget.innerHTML = replaceOrRemoveString.replace(
        "${count}",
        filesWithErrorsCount.toString()
      );
    }

    return hasErrors;
  }

  goToDocument() {
    const firstErrorElement = this.documentListTarget
      ?.firstElementChild as HTMLElement;
    const firstTextElement = document.querySelectorAll(
      `#${firstErrorElement?.id} p`
    )[0] as HTMLElement;
    this.removeOrReplaceErrorMessageTarget?.classList.toggle("hidden");
    document
      .getElementById("scrollForDocList")!
      .scroll({ top: 0, left: 0, behavior: "smooth" });
    firstTextElement?.focus();
  }

  truncateExccessFiles() {
    if (this.documentListTarget?.children) {
      while (this.documentListTarget.children.length > MAX_NUMBER_OF_FILES) {
        const lastChild = this.documentListTarget
          ?.lastElementChild as HTMLElement;
        this.documentListTarget?.removeChild(lastChild);
        this.globalFileList.items.remove(this.globalFileList.items.length - 1);
      }
    }
  }

  toggleTooManyDocumentsPopup() {
    this.tooManyDocumentsWarningTarget?.classList.toggle(
      "hidden",
      (this.documentListTarget?.children.length ?? 0) <= MAX_NUMBER_OF_FILES
    );
  }

  getIconName(fileType: string) {
    switch (true) {
      case fileType.includes("text"):
        return FILE_TEXT;
      case fileType.includes("image"):
        return FILE_IMAGE;
      case fileType.includes("wordprocessingml"):
        return FILE_WORD;
      case fileType.includes("application/pdf"):
        return FILE_PDF;
      case fileType.includes("sheet"):
        return FILE_EXCEL;
      case fileType.includes("presentation"):
        return FILE_PPT;
      case fileType.includes("application/rtf"):
        return FILE_TEXT;
      case fileType.includes("audio"):
        return FILE_AUDIO;
      case fileType.includes("video"):
        return FILE_VIDEO;
      default:
        return FILE_GENERIC;
    }
  }

  getIconColor(fileType: string) {
    switch (true) {
      case fileType.includes("text"):
        return "bg-blue-200";
      case fileType.includes("image"):
        return "bg-purple-200";
      case fileType.includes("wordprocessingml"):
        return "bg-blue-200";
      case fileType.includes("application/pdf"):
        return "bg-coral-200";
      case fileType.includes("sheet"):
        return "bg-mint-200";
      case fileType.includes("presentation"):
        return "bg-purple-200";
      case fileType.includes("application/rtf"):
        return "bg-grey-200";
      case fileType.includes("audio"):
        return "bg-purple-200";
      case fileType.includes("video"):
        return "bg-purple-200";
      default:
        return "bg-grey-200";
    }
  }

  updateAttachmentHeaderCount() {
    const selectedFileCount = this.documentListTarget?.children.length ?? 0;

    this.attachmentHeaderTarget!.innerHTML = `${selectedFileCount} ${
      selectedFileCount === 1
        ? this.attachmentTextValue
        : this.attachmentsTextValue
    }`;

    if (selectedFileCount == 0) {
      this.attachmentFieldsTarget?.classList.add("hidden");
    }
  }

  addAttachment(file: File) {
    this.globalFileList.items.add(file);
  }

  removeAttachment(fileName: string, lastModified: string) {
    for (let i = 0; i < this.globalFileList.items.length; i++) {
      const currentFile = this.globalFileList.files[i];
      if (
        currentFile.name === fileName &&
        currentFile.lastModified.toString() === lastModified
      ) {
        this.globalFileList.items.remove(i);
        return;
      }
    }
  }

  replaceAttachment(newFile: File, oldFileName: string) {
    const replaceFileList = new DataTransfer();
    let replacedYet = false;
    for (let i = 0; i < this.globalFileList.items.length; i++) {
      const currentFile = this.globalFileList.files[i];
      if (currentFile.name === oldFileName && replacedYet === false) {
        replaceFileList.items.add(newFile);
        replacedYet = true;
      } else {
        replaceFileList.items.add(currentFile);
      }
    }
    this.globalFileList = replaceFileList;
  }

  clearFlashAlerts() {
    this.flashContainerTarget?.classList.add("hidden");
  }

  private displayProgressBar(
    uploadedDocuments: number,
    totalDocuments: number
  ) {
    if (totalDocuments === 0) {
      return;
    }

    this.progressBarOverlayTarget?.classList.remove("hidden");
    this.formTarget?.classList.add("pointer-events-none");

    this.updateProgressBarMessage(uploadedDocuments, totalDocuments);
  }

  private updateProgressBarMessage(
    uploadedDocuments: number,
    totalDocuments: number
  ) {
    const currentSendingDocument = uploadedDocuments + 1;
    const progressBarSingleMessage = this.sendingDocumentTextValue?.replace(
      "%{count}",
      totalDocuments + ""
    );
    const progressBarBulkMessage = this.sendingDocumentsTextValue
      ?.replace("%{total}", totalDocuments + "")
      .replace("%{count}", currentSendingDocument + "");

    this.progressBarMessageTarget!.innerHTML =
      totalDocuments === 1
        ? progressBarSingleMessage ?? ""
        : progressBarBulkMessage ?? "";

    this.progressBarMessageTarget?.focus(); // a11y: Focus on label so it's read immediately after sending
  }

  private createS3FileUploadRequest(
    file: File | undefined,
    put_headers: DocumentCreated["latest_document_version"]["put_headers"],
    put_url: string
  ): [() => Promise<{ status?: number } & ProgressEvent>, XMLHttpRequest] {
    const uploadRequest = new XMLHttpRequest();
    uploadRequest.open(HTTP_METHOD.Put, put_url);

    uploadRequest.upload.onprogress = (res: ProgressEvent) => {
      const uploadPercent = res.loaded / res.total;
      const uploadSharePerDocument = 100 / this.globalFileList.items.length;

      const uploadProgress =
        uploadPercent * uploadSharePerDocument +
        uploadSharePerDocument * this.uploadedDocumentCount;

      // the total is the percentage of the share (e.g. 25% of one document when there are ten documents = 2.5% of the total),
      // plus the total of all documents that have been uploaded (e.g. three files have been uploaded = 30 + 2.5 = 32.5%)
      this.progressBarTarget!.style.width = `${uploadProgress}%`;

      this.updateProgressBarMessage(
        this.uploadedDocumentCount,
        this.globalFileList.files.length
      );
    };

    uploadRequest.setRequestHeader(put_headers[0].name, put_headers[0].value); // Encryption header
    uploadRequest.setRequestHeader(put_headers[1].name, put_headers[1].value); // Content-Type;

    const sendFile = async (): Promise<{ status?: number } & ProgressEvent> => {
      return new Promise((resolve, reject) => {
        uploadRequest.onload = (event: ProgressEvent) => resolve(event);
        uploadRequest.onerror = reject;
        uploadRequest.send(file);
      });
    };

    return [sendFile, uploadRequest];
  }
}

// Need to add the svg elements here for the icons as currently loading the svg from their files would require a lot of resources.
// TODO: Find a better approach to load the svg from their files and manipulate the styling for it.
const FILE_IMAGE =
  '<svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="text-purple-500 w-6 h-6"><path fill="currentColor" fill-rule="evenodd" d="M3.879 1.879A3 3 0 0 1 6 .999h7a1 1 0 0 1 .707.294l7 7A1 1 0 0 1 21 9v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V4a3 3 0 0 1 .879-2.121zM6 2.999a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.415L12.586 3H6z" clip-rule="evenodd"/><path fill="currentColor" fill-rule="evenodd" d="M13 1a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" clip-rule="evenodd"/><path fill="currentColor" d="M11 11a2 2 0 11-4 0 2 2 0 014 0zM16 19H8a1 1 0 01-1-1v-1.5L9 15l2.5 1 3-3 2.5 2.5V18a1 1 0 01-1 1z"/></svg>';
const FILE_PDF =
  '<svg aria-hidden="true"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="text-coral-500 w-6 h-6"><path fill="currentColor" fill-rule="evenodd" d="M3.879 1.879A3 3 0 0 1 6 .999h7a1 1 0 0 1 .707.294l7 7A1 1 0 0 1 21 9v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V4a3 3 0 0 1 .879-2.121zM6 2.999a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.415L12.586 3H6z" clip-rule="evenodd"/><path fill="currentColor" fill-rule="evenodd" d="M13 1a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" clip-rule="evenodd"/><path fill="currentColor" d="M16.276 15.346c-.483-.452-1.89-.34-2.614-.264-.684-.377-1.126-.942-1.448-1.734.12-.603.402-1.546.201-2.112-.16-.98-1.528-.904-1.73-.226-.16.604 0 1.47.282 2.526a39.535 39.535 0 0 1-1.407 2.828c-.805.377-1.89.98-2.051 1.735-.121.603 1.045 2.073 3.056-1.17.885-.301 1.89-.64 2.735-.791.764.415 1.649.64 2.252.64a.857.857 0 0 0 .724-1.432zm-7.963 2.941c.201-.528.965-1.131 1.206-1.32-.764 1.132-1.206 1.32-1.206 1.32zm3.257-7.201c.322 0 .282 1.206.08 1.546-.16-.528-.16-1.546-.08-1.546zm-.965 5.165c.402-.64.724-1.395 1.006-2.074.321.566.723 1.018 1.206 1.32-.844.188-1.568.49-2.212.754zm5.269-.189s-.202.227-1.488-.301c1.407-.113 1.649.188 1.488.302z"/></svg>';
const FILE_PPT =
  '<svg aria-hidden="true"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="text-purple-500 w-6 h-6"><path fill="currentColor" fill-rule="evenodd" d="M3.879 1.879A3 3 0 0 1 6 .999h7a1 1 0 0 1 .707.294l7 7A1 1 0 0 1 21 9v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V4a3 3 0 0 1 .879-2.121zM6 2.999a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.415L12.586 3H6z" clip-rule="evenodd"/><path fill="currentColor" fill-rule="evenodd" d="M11.302 16.67c-.111-.004-.207-.007-.282-.007v1.725c0 .2-.199.362-.442.362H9.442c-.243 0-.442-.163-.442-.362v-6.026c0-.2.2-.362.442-.362h2.986c1.64 0 2.688.99 2.688 2.322 0 2.475-2.704 2.385-3.814 2.348zm1.491-3.008c-.177-.163-.424-.244-.748-.244h-1.029v1.83h.992c.34 0 .597-.087.778-.256.369-.344.361-1.001.007-1.33zM13 1a1 1 0 011 1v6h6a1 1 0 110 2h-7a1 1 0 01-1-1V2a1 1 0 011-1z" clip-rule="evenodd"/></svg>';
const FILE_WORD =
  '<svg aria-hidden="true"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="text-blue-500 w-6 h-6"><path fill="currentColor" fill-rule="evenodd" d="M3.879 1.879A3 3 0 0 1 6 .999h7a1 1 0 0 1 .707.294l7 7A1 1 0 0 1 21 9v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V4a3 3 0 0 1 .879-2.121zM6 2.999a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.415L12.586 3H6z" clip-rule="evenodd"/><path fill="currentColor" d="M14.4 13.352c.038-.211.225-.352.45-.352h.563c.3 0 .525.281.45.527-.15.598-.525 1.899-1.275 4.782a.47.47 0 0 1-.45.316H13.2c-.187 0-.375-.14-.412-.316-.225-.739-.675-2.497-.675-2.497L12 15.18l-.112.633-.675 2.496c-.038.21-.225.316-.45.316h-.938a.47.47 0 0 1-.45-.316c-.225-.915-.937-3.622-1.237-4.782-.075-.246.15-.527.45-.527h.637c.225 0 .375.14.45.352.188.914.675 3.304.713 3.48v.14c.037-.245.037-.14.937-3.62.038-.211.225-.352.45-.352h.488c.225 0 .375.14.45.352.9 3.48.825 3.304.862 3.48.038.07.038.105.038.14.037-.28 0-.175.787-3.62z"/><path fill="currentColor" fill-rule="evenodd" d="M13 1a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" clip-rule="evenodd"/></svg>';
const FILE_EXCEL =
  '<svg aria-hidden="true"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="text-mint-500 w-6 h-6"><path fill="currentColor" fill-rule="evenodd" d="M3.879 1.879A3 3 0 0 1 6 1h7a1 1 0 0 1 .707.293l7 7A1 1 0 0 1 21 9v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V4a3 3 0 0 1 .879-2.121zM6 3a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.414L12.586 3H6z" clip-rule="evenodd"/><path fill="currentColor" d="M13.12 12h.994a.42.42 0 0 1 .356.633c-.553.967-1.575 2.703-1.6 2.742l1.603 2.742a.422.422 0 0 1-.359.633h-.995a.415.415 0 0 1-.366-.221c-.784-1.47-.473-.967-.988-2.029-.193.447-.238.622-.987 2.029a.41.41 0 0 1-.363.221h-.998a.42.42 0 0 1-.36-.633l1.6-2.742-1.6-2.742a.422.422 0 0 1 .36-.633h.998c.152 0 .294.084.366.222.75 1.42.508 1.005.988 2.028.221-.538.366-.865.988-2.028a.41.41 0 0 1 .362-.222z"/><path fill="currentColor" fill-rule="evenodd" d="M13 1a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" clip-rule="evenodd"/></svg>';
const FILE_TEXT =
  '<svg aria-hidden="true"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="text-blue-500 w-6 h-6"><path fill="currentColor" fill-rule="evenodd" d="M3.879 1.879A3 3 0 0 1 6 .999h7a1 1 0 0 1 .707.294l7 7A1 1 0 0 1 21 9v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V4a3 3 0 0 1 .879-2.121zM6 2.999a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.415L12.586 3H6z" clip-rule="evenodd"/><path fill="currentColor" fill-rule="evenodd" d="M13 1a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" clip-rule="evenodd"/><path fill="currentColor" d="M7 13a1 1 0 011-1h8a1 1 0 110 2H8a1 1 0 01-1-1zM7 9a1 1 0 011-1h1a1 1 0 110 2H8a1 1 0 01-1-1zM7 17a1 1 0 011-1h8a1 1 0 110 2H8a1 1 0 01-1-1z"/></svg>';
const FILE_AUDIO =
  '<svg aria-hidden="true"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="text-purple-500 w-6 h-6"><path fill="currentColor" fill-rule="evenodd" d="M3.879 1.879A3 3 0 0 1 6 1h7a1 1 0 0 1 .707.293l6.999 6.999A.997.997 0 0 1 21 9v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V4a3 3 0 0 1 .879-2.121zM6 3h6v6a1 1 0 0 0 1 1h6v10a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm8 1.414L17.586 8H14V4.414zM10.75 10a.75.75 0 0 0-.75.75v7.5a.75.75 0 0 0 1.5 0v-7.5a.75.75 0 0 0-.75-.75zm2.5 2a.75.75 0 0 0-.75.75v3.5a.75.75 0 0 0 1.5 0v-3.5a.75.75 0 0 0-.75-.75zM15 13.75a.75.75 0 0 1 1.5 0v1.5a.75.75 0 0 1-1.5 0v-1.5zM8.25 13a.75.75 0 0 0-.75.75v1.5a.75.75 0 0 0 1.5 0v-1.5a.75.75 0 0 0-.75-.75z" clip-rule="evenodd"/></svg>';
const FILE_VIDEO =
  '<svg aria-hidden="true"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="text-purple-500 w-6 h-6"><path fill="currentColor" fill-rule="evenodd" d="M3.879 1.879A3 3 0 0 1 6 .999h7a1 1 0 0 1 .707.294l7 7A1 1 0 0 1 21 9v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V4a3 3 0 0 1 .879-2.121zM6 2.999a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.415L12.586 3H6z" clip-rule="evenodd"/><path fill="currentColor" fill-rule="evenodd" d="M13 1a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" clip-rule="evenodd"/><path fill="currentColor" d="M9.5 10.934v6.132a.5.5 0 0 0 .777.416l4.599-3.066a.5.5 0 0 0 0-.832l-4.599-3.066a.5.5 0 0 0-.777.416z"/></svg>';
const FILE_GENERIC =
  '<svg aria-hidden"true"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="text-grey-500 w-6 h-6"><path fill="currentColor" fill-rule="evenodd" d="M3.879 1.879A3 3 0 0 1 6 .999h7a1 1 0 0 1 .707.294l7 7A1 1 0 0 1 21 9v11a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V4a3 3 0 0 1 .879-2.121zM6 2.999a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.415L12.586 3H6z" clip-rule="evenodd"/><path fill="currentColor" fill-rule="evenodd" d="M13 1a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2h-7a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" clip-rule="evenodd"/></svg>';
const CLOSE_ICON =
  '<svg aria-hidden="true"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="text-grey-600 mr-2 mb-2 w-6 h-6"><path fill="currentColor" fill-rule="evenodd" d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414z" clip-rule="evenodd"/></svg>';
const ALERT_CIRCLE =
  '<svg aria-hidden="true"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" class="text-red-500 w-6 h-6"><path fill="currentColor" fill-rule="evenodd" d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18zM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12zm11-5a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1zm-1 9a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2H12a1 1 0 0 1-1-1z" clip-rule="evenodd"/></svg>';
