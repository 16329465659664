import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["entries", "top", "root"];

  intersectionObserver?: IntersectionObserver;
  topTarget?: HTMLElement;
  entriesTarget?: HTMLElement;
  rootTarget?: HTMLElement;
  nextPage?: String;

  initialize() {
    this.nextPage = this.entriesTarget?.dataset.nextPage;
    this.intersectionObserver = new IntersectionObserver(
      (entries) => this.processIntersectionEntries(entries),
      { root: this.rootTarget, rootMargin: "300px" }
    );
    this.showMessages();
  }

  connect() {
    if (this.topTarget) this.intersectionObserver?.observe(this.topTarget);
  }

  disconnect() {
    if (this.topTarget) this.intersectionObserver?.unobserve(this.topTarget);
  }

  processIntersectionEntries(entries: IntersectionObserverEntry[]) {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        return this.loadMore();
      }
    }
  }

  loadMore(): Promise<void> {
    return new Promise((resolve) => {
      if (!this.nextPage) {
        resolve();
        return;
      }
      fetch(this.nextPage as any, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.nextPage = data.next_page;
          this.topTarget?.insertAdjacentHTML("beforebegin", data.entries);
          this.showMessages();
          resolve();
        });
    });
  }

  showMessages() {
    if (this.nextPage) return; //messages are only shown at the very top of the message list, so skip if there are things to be loaded
    this.rootTarget
      ?.querySelectorAll(".portal-top-message")
      .forEach((el) => el.classList.remove("hidden"));
  }
}
